import React from "react"

const Email = () => (
  <div>
    <h2>Confirm Change of Email</h2>

    <p>Follow this link to confirm your change of email:</p>
    <p><a href="{{ .SiteURL }}/admin/#email_change_token={{ .Token }}">Change Email</a></p>
  </div>
)

export default Email
